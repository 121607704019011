import React from 'react';

const HowItWork = () => {
  return (
    <div className="flex flex-col justify-center items-center p-6 sm:p-8 md:p-10 lg:p-12 xl:p-14 2xl:p-16 mt-28" style={{ background: "#51CCD4" }}>
      <h1 className="text-left font-normal text-3xl md:text-3xl lg:text-3xl xl:text-3xl font-montserrat tracking-tighter text-white opacity-100">How it works</h1>
      <hr className="w-36 hr-style mt-4" />
      <p className="text-white mt-7   text-center" style={{maxWidth:"590px", fontSize:"21px"}}>BabyStop App uses parent-generated feedback to find top recommended baby-friendly spots. Our algorithm searches for the nearest spaces that offer the amenities parents need the most and shows you results based on the 3 most critical criteria.</p>
    </div>
  );
};

export default HowItWork;
