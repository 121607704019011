import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './pages/landing-page'

function App() {
 
  return (
   <div className='App'>
    <BrowserRouter>
        <Routes>
          <Route path='' element={<LandingPage/>}/>
        </Routes>
    </BrowserRouter>
   </div>
  )
}

export default App
