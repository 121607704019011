import React from "react";

// import Body from "./Body";
import Header from "./Header";

import HowItWork from "./HowItWork";

import Footer from "./Footer";
import Details from "./Details";
import Testimonial from "./Testimonial";

const LandingPage = () => {
  return (
    <div>
      <Header />

      {/* <Body /> */}
      <HowItWork />
      <Testimonial />
      <Details />
      <Footer />
    </div>
  );
};

export default LandingPage;
