import React, { useState } from "react";
import { Link } from "react-router-dom";

const Details = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleJoinWaitList = () => {
    // Set loading to true when the button is clicked
    setIsLoading(true);

    // Simulate an asynchronous operation (like making an API request)
    setTimeout(() => {
      // After some time (simulated delay), set loading to false
      setIsLoading(false);
    }, 2000); // Simulated delay of 2 seconds
  };

  return (
    <div className="flex flex-col justify-center items-center p-6 sm:p-8 md:p-10 lg:p-12 xl:p-14 2xl:p-16">
      <div className="max-w-3xl mt-10">
        <h1
          className="font-medium text-center pt-10"
          style={{ fontSize: "33px", maxWidth: "590px" ,color:"#51CCD4" }}
        >
          We get how difficult these years are but we also know these are the
          most precious.
        </h1>
      </div>

      <hr className="w-36 hr-styledetails mt-14" />

      <p
        className="text-lg text-center font-medium mt-10"
        style={{ maxWidth: "750px", color: "#000000" }}
      >
        We want you to take a hundred pictures, capture every moment and go
        outside worry-free. Travel with your bubs and leave the anxiety of
        heading out with your baby behind! Let BabyStop App take care of finding
        family-friendly spaces while you soak in every moment!
      </p>

      {isLoading ? (
        <div className="flex justify-center items-center mt-20">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only text-black">Loading...</span>
          </div>
        </div>
      ) : (
        <a
          href="https://forms.gle/vHfEGjVQbt7AEhHf9"
          className="text-white rounded-md px-4 mt-20 py-3"
          style={{ background: "#51CCD4", textDecoration: "none" }}
          onClick={handleJoinWaitList}
        >
          Join the WaitList
        </a>
      )}
    </div>
  );
};

export default Details;



// import React from "react";
// import { Link } from "react-router-dom";

// const Details = () => {
//   return (
//     <div className="flex flex-col justify-center items-center p-6 sm:p-8 md:p-10 lg:p-12 xl:p-14 2xl:p-16">
//       <div className="max-w-3xl mt-10">
//         <h1
//           className="font-medium text-center pt-10"
//           style={{ fontSize: "33px", maxWidth: "590px" }}
//         >
//           We get how difficult these years are but we also know these are the
//           most precious.
//         </h1>
//       </div>

//       <hr className="w-36 hr-styledetails mt-14" />

//       <p
//         className="text-lg text-center font-medium mt-10"
//         style={{ maxWidth: "750px", color: "#000000" }}
//       >
//         We want you to take a hundred pictures, capture every moment and go
//         outside worry-free. Travel with your bubs and leave the anxiety of
//         heading out with your baby behind! Let BabyStop App take care of finding
//         family-friendly spaces while you soak in every moment!
//       </p>

//       <a
//         href="https://forms.gle/vHfEGjVQbt7AEhHf9"
//         className="text-white rounded-md px-4 mt-20 py-3"
//         style={{ background: "#51CCD4", textDecoration: "none" }}
//       >
//         Join the WaitList
//       </a>
//     </div>
//   );
// };

// export default Details;
