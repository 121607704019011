import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import backgroundImage from "../../assets/Ornament.svg";
import ist from "../../assets/a.PNG";
import second from "../../assets/b.PNG";
import tiktok from "../../assets/tiktok.png";
import instagram from "../../assets/instagram.png";
import NavbarLogo from "../../assets/NavbarLogo.svg";

function Header() {
  const [showBackground, setShowBackground] = useState(false);
  const [loadingnav, setLoadingnav] = useState(false);
  const [loadingBackgroundButton, setLoadingBackgroundButton] = useState(false);

  const handleClickNav = () => {
    setLoadingnav(true);

    setTimeout(() => {
      setLoadingnav(false);

      window.location.href = "https://forms.gle/vHfEGjVQbt7AEhHf9";
    }, 2000);
  };

  const handleBackgroundButtonClick = () => {
    setLoadingBackgroundButton(true);

    setTimeout(() => {
      setLoadingBackgroundButton(false);

      window.location.href = "https://forms.gle/vHfEGjVQbt7AEhHf9";
    }, 2000);
  };

  useEffect(() => {
    const handleResize = () => {
      setShowBackground(window.innerWidth >= 800);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative">
      {showBackground && (
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            top: "-350.63677978515625px",
            left: "-340.4325866699219px",
            maxWidth: "1430px",
            height: "1061px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: "0.5",
            zIndex: "999",
          }}
          onClick={handleBackgroundButtonClick}
        ></div>
      )}

      <div className="overlay-container">
        <Navbar
          collapseOnSelect
          expand="lg"
          className=""
          style={{ background: "#E5F8F9", }}
        >
          <Container>
            <Navbar.Brand href="#home">
              <img src={NavbarLogo} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto items-center my-2 gap-3">
                <Nav.Link href="#blog" className="text-black font-bold">
                  Blog
                </Nav.Link>
                <Nav.Link href="#contact us" className="text-black font-bold">
                  Contact Us
                </Nav.Link>

                <Nav.Link href="#contact us" className="text-black font-bold">
                  Media
                </Nav.Link>
                <Nav.Link href="#tiktok" className="text-black">
                  <a
                    href="https://www.tiktok.com/@babystop.app?_t=8mCjVZCuvob&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={tiktok} alt="" />
                  </a>
                </Nav.Link>
                <Nav.Link href="#instagram" className="text-black">
                  <a
                    href="https://www.instagram.com/babystop.app?igsh=azBwNXBjbTZwc3E3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={instagram} alt="" />
                  </a>
                </Nav.Link>

                <Nav.Link
                  onClick={handleClickNav}
                  className="text-white rounded-md p-3"
                  style={{ background: "#51CCD4", padding: "10px" }}
                >
                  {loadingnav ? "Loading..." : "Join the WaitList"}
                </Nav.Link>

                <Nav.Link
                 
                  className="text-white rounded-md p-3"
                  style={{ background: "#51CCD4", padding: "10px" }}
                >
                  I am a Business Owner
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="container  ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center gap-9 justify-between  ">
            <div className="w-full flex flex-col items-start ">
              <h1 className=" mb-4" style={{ fontSize: "43px" }}>
                BabyStop App, when you need to make a pit stop for your baby!
              </h1>
              <p className=" py-2  mb-4" style={{ fontSize: "20px" }}>
                Say goodbye to staying indoors because you're concerned you
                won't find baby-friendly places. BabyStop App is designed to
                connect parents with baby-friendly restaurants, coffee shops,
                malls, parks, you name it! We ensure all facilities that offer
                changing stations and are nursing-friendly are included in our
                search results.
              </p>
              <p className="text-2xl font-semibold mb-4">
                Launching summer 2024
              </p>
              <button
                onClick={handleBackgroundButtonClick}
                className="text-white rounded-md px-4 cursor-pointer py-3 "
                style={{ background: "#51CCD4" }}
              >
                {loadingBackgroundButton ? "Loading..." : "Join the WaitList"}
              </button>
            </div>

            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-16">
              <div className="col-span-1 md:col-span-2 lg:col-span-1 mt-24">
                <img src={second} alt="New First" className="w-full h-[600px] rounded-xl" />
              </div>
              <div className="col-span-1 md:col-span-2 lg:col-span-1 mt-12">
                <img src={ist} alt="New Second" className="w-full h-[600px] rounded-xl border-2 border-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
