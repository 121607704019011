import React from "react";
import breastfeeding from "../../assets/breastfeeding.png"; 
import test2 from "../../assets/test2.png"; 

import test3 from "../../assets/test3.png"; 


const testimonials = [
  {
    id: 1,
    image: test2,
   
    title: "Changing Station",
    description:
      "At BabyStop, we know how difficult it is to find clean changing stations, this is why we rely on other parents to provide live feedback on the cleanliness of facilities",
  },
  {
    id: 2,
    image: breastfeeding,
    title: "Nursing Friendly",
    description:
      "In this day and age we love to work with and promote locations that show love and respect to moms (and dads!). BabyStop App will show you all spots that are nursing friendly.",
  },
  {
    id: 3,
    image: test3,
    title: "Microwave",
    description:
      "We understand that not all parents have the same needs and sometimes you might also need to give your baby solids. BabyStop app brings you recommended spaces that will happily help heat food for your little ones.",
  },
];

const Testimonial = () => {
  return (
    <div className="container mx-auto px-4 py-8 mt-20">
    <div className="flex flex-col md:flex-row justify-center items-center md:space-x-8">
      {testimonials.map((testimonial) => (
        <div
          key={testimonial.id}
          className="flex flex-col items-center rounded-lg"
        >
          <div className="p-4 rounded-full bg-[#51CCD4]">
            <img
              className="w-20 h-20" // Specific width and height
              src={testimonial.image}
              alt={testimonial.title}
            />
          </div>
          <h2 className="text-2xl font-semibold text-center mt-4 mb-6">
            {testimonial.title}
          </h2>
          <p className="text-center font-medium">{testimonial.description}</p>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default Testimonial;
