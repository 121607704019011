import React from "react";
import logo from "../../assets/logo.png";
import tiktok from "../../assets/tiktok.png";
import instagram from "../../assets/instagram.png";

const Footer = () => {
  return (
    <div className="mt-24 py-12 px-4" style={{ background: "#51CCD4" }}>
      <div className="flex justify-center mb-6">
        <img src={logo} alt="Logo" />
      </div>
      <div className="flex justify-center gap-6 mb-6">
        <a href="https://www.tiktok.com/@babystop.app?_t=8mCjVZCuvob&_r=1" target="_blank" rel="noopener noreferrer">
          <img className="bg-white p-2 rounded-full" src={tiktok} alt="TikTok" />
        </a>
        <a href="https://www.instagram.com/babystop.app?igshid=azBwNXBjbTZwc3E3" target="_blank" rel="noopener noreferrer">
          <img className="bg-white p-2 rounded-full" src={instagram} alt="Instagram" />
        </a>
      </div>
      <div className="flex justify-center">
        <h1 className="text-white font-bold text-lg">contact@babystop.app</h1>
      </div>
    </div>
  );
};

export default Footer;
